.button-modal {
    background-color: #1890ff;
    color: white;
    font-size: 16px;
    border-radius: 6px;
    height: 40px;  
    font-family: "MontserratRegular";

  }
  
  .button-modal:hover {
    background-color: #40a9ff;
  }
  