.logs-actions{
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .logs-data{
    font-style: normal;
    font-family: "MontserratRegular";
    font-size: 15px;
    line-height: 24px;
    overflow-wrap:break-word;
    word-break:break-word;
    white-space:normal;
  
  }
  div[aria-hidden="true"] {
    display: none;
    }

  .search-logs {
    display: grid;
    grid-template-columns: 50% 50%;
    gap: 10px;
    margin-bottom: 1em;
    margin-top: 2em;
    }
    .search-logs-item {
    font-family: "MontserratRegular";
    font-size: 15px;
    }
    
    @media screen and (max-width: 992px) {
    .search-logs {
      grid-template-columns: 100%;
    }
    .search-logs-item {
       width: 100%;
    }
    }
    
    @media screen and (min-width: 576px) {
    .search-logs-item{
        width: 100%;
      }
   }