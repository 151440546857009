th {
  font-family: "MontserratSemiBold";
  font-weight: 600;
  font-size: 15px;
  text-align: center !important;
}

td {
  font-family: "MontserratRegular";
  font-weight: 400;
  font-size: 14px;
  text-align: center;
}

.ant-table {
  width: 100%;
  font-family: "MontserratRegular";
  font-weight: 400;
}

.ant-table-tbody>tr>td {
  background: #fff;
  text-align: center;
}

@media (min-width: 992px) {
  .table-wrapper {
    width: 100%;
    overflow-x: auto;
  }
}

@media (max-width: 768px) {
  .ant-table-wrapper {
    overflow-x: auto;
  }

  .ant-table-thead>tr>th {
    white-space: nowrap;
  }

  .ant-table-tbody>tr>td {
    white-space: nowrap;
    background: #fff;
    text-align: center;
  }
}