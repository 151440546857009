:root {
    --main-color: #041370;
    --secondary-color: #5955d9;
    --third-color: #f25e6b;
    --gris-obscuro: #808080;
    --normal-color: #222831;
    --background-color: #ffffff;
  }
  
  .title {
    font-family: "MontserratBold";
    font-weight: 700;
    font-size: 32px;
    color: var(--main-color);
    padding-top: 0;
    margin: 0;
    line-height: 1;
  }
@media (max-width: 768px) {
    .title {
      font-size: 24px; 
      line-height: 1.2; 
    }
  }
  
  @media (max-width: 480px) {
    .title {
      font-size: 18px; 
      line-height: 1.3; 
      text-align: center; 
    }
  }
  .subtitle {
    font-weight: 800;
    font-size: 24px;
    color: var(--gris-obscuro);
  }
  
  .textbody {
    font-weight: 600;
    font-size: 12px;
    color: var(--normal-color);
  }
  
  .tag {
    padding: 0.1em 0.5em;
    color: var(--main-color);
    font-weight: 800;
    border-radius: 20px;
    box-shadow: 0px 3px 5px 0px rgba(181, 181, 181, 0.5);
  }
  