body {
  background-color: #f0f5f8 !important;
}

.ant-layout-sider {
  background: #f0f5f8 !important;
}

.header-dash {
  display: flex;
}

.logo-dash {
  height: 70px;
  display: none;
}

.content-dash {
  overflow: auto;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  width: 100%;

}

@media screen and (max-width: 992px) {
  .content-dash {}
}

@media screen and (min-width: 576px) {
  .content-dash {}
}

.content-pages-dash {
  background: #f0f5f8;
  display: flex;
  justify-content: center;
  margin: 1.1em  0.4em  0.4em  0.7em;
  

}

@media screen and (max-width: 992px) {
  .content-pages-dash {
    margin: 0.7em  0.4em  0.4em  0.4em;
  }
}

@media screen and (min-width: 576px) {
  .content-pages-dash {
    margin: 1.1em  0.4em  0.4em  0.4em;


  }
}

.footer-dash {
  text-align: center;
  background: rgba(255, 255, 255, 0.3);
}

@media (min-width: 992px) {
  .menuOutlined-dash {
    display: none;
  }
}

.sidebar {
  display: none;
}

@media (min-width: 992px) {
  .sidebar {
    display: block;
  }
}

@media (min-width: 992px) {
  .logo-dash {
    display: block;
  }
}

.icon-event {
  margin-left: 43px;
}

.ant-menu>.ant-menu-item-selected .icon-event {
  position: relative;
  width: 20px;
  height: 26px;
  background: white;
  border-top-left-radius: 50px;
  border-bottom-left-radius: 50px;
  margin-left: 25px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.ant-menu>.ant-menu-item-selected .icon-event::before {
  content: "";
  position: absolute;
  right: 0;
  top: -10px;
  width: 10px;
  height: 10px;
  background: transparent;
  border-bottom-right-radius: 10px;
  box-shadow: 5px 5px 0 5px white;
}

.ant-menu>.ant-menu-item-selected .icon-event::after {
  content: "";
  position: absolute;
  right: 0;
  bottom: -10px;
  width: 10px;
  height: 10px;
  background: transparent;
  border-top-right-radius: 10px;
  box-shadow: 5px -5px 0 5px white;
}

.ant-menu>.ant-menu-item:hover,
.ant-menu>.ant-menu-submenu:hover,
.ant-menu>.ant-menu-item-active,
.ant-menu>.ant-menu-submenu-active,
.ant-menu>.ant-menu-item-open,
.ant-menu>.ant-menu-submenu-open,
.ant-menu>.ant-menu-item-selected,
.ant-menu>.ant-menu-submenu-selected:before,
.ant-menu>.ant-menu-submenu-selected:after {
  color: #00dd9e !important;
  border-right: none !important;
  transition: none !important;
}

.ant-menu>.ant-menu-item-selected .icon-event .event {
  width: 9px;
  height: 9px;
  background: #041370;
  border-radius: 50%;
  z-index: 999;
}

.ant-menu-horizontal,
.ant-menu-item::after,
.ant-menu-submenu::after {
  border: none !important;
}

.bar {
  padding-top: 35px !important;
  padding-bottom: 35px !important;
  display: flex;
  background: linear-gradient(90deg, #041370 40.1%, #4100f3 100%) !important;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  top: 0px;
  z-index: 10;
}

@media screen and (min-width: 576px) {
  .bar {
    padding-left: 35px !important;
    padding-right: 35px !important;
  }
}

@media screen and (max-width: 992px) {
  .bar {
    padding-left: 12px !important;
    padding-right: 12px !important;
  }
}


.ant-layout-sider .ant-layout-sider-dark .ant-layout-sider-has-trigger {}

.ant-layout {}


.ant-layout-sider-trigger {
  background-color: red;
}

.ant-menu.menu-sidebar {
  background: linear-gradient(180deg, #041370 0%, #4100f3 100%) !important;
  text-align: center !important;
  border-radius: 20px !important;
  margin: 15px 0px 15px 14.5px !important;
  width: 100px !important;
    /* height: calc(100% - 3.2%) !important; */
  height: auto;
  /* min-height: calc(100% - 3.2%) !important; */
  color: #ffffff !important;
  font-size: 12px;
  padding-top: 1em !important;
  padding-bottom: 1em;
  /* position: fixed */
}
.ant-layout-sider{
  max-width: 123px !important;
  min-width: 123px !important;
  width: 123px !important;    
}

.ant-menu.menu-sidebar-fixed {
  background: linear-gradient(180deg, #041370 0%, #4100f3 100%) !important;
  text-align: center !important;
  border-radius: 20px !important;
  margin: 15px 0px 15px 20px !important;
  width: 100px !important;
  height: auto;
  /* min-height: calc(100vh - 16%); */
  color: #ffffff !important;
  font-size: 12px;
  padding-top: 1em !important;
  padding-bottom: 1em;
  position: fixed
}
.trigger {
  bottom: 0;
  z-index: 1;
  height: 48px;
  color: #fff;
  line-height: 48px;
  text-align: center;
  background: transparent;
  cursor: pointer;
  transition: all 0.2s;
  width: 98.7px !important;
  padding: 0px;
  margin: 0px 0px 1em 0px;
  border-radius: 0px 0px 20px 20px !important;
}

.username {
  color: #00DD9E !important;
  font-family: "MontserratBold";
  font-size: 16px !important;
  font-weight: 600 !important;
  line-height: 24px !important;
  letter-spacing: 0em !important;
  text-align: left !important;
}

.container {
  padding: 0px !important;
  display: flex !important;
  align-items: center;
  margin: 0px !important;
}

.containerText {
  display: flex !important;
  flex-direction: column !important;
  margin-left: 9px;
}

.containerRight {
  display: flex !important;
  align-items: flex-start !important;
  align-items: center !important;
  margin-left: 4px !important;
}

.occupation {
  font-family: "MontserratBold";
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 12px !important;
  line-height: 18px !important;
  color: #FFFFFF !important;
}

.usernameSecond {
  color: #000000 !important;
  font-family: "MontserratBold";
  font-size: 20px !important;
  font-weight: 500 !important;
  line-height: 30px !important;
  letter-spacing: -0.24px !important;
  text-align: center !important;
  font-style: normal !important;
}

.occupationSecond {
  font-family: "MontserratBold";
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 21px !important;
  letter-spacing: -0.24px !important;
  color: #A0A0A0 !important;
  text-align: center !important;
}

.containerSecond {
  margin-bottom: 18px !important;
}

.containerTextSecond {
  display: flex !important;
  flex-direction: column !important;

}

.buttonContent {
  display: flex !important;
  padding: 0px 14px 0px 14px !important;

}

.first-button {
  width: 112px !important;
}

.second-button {
  margin-left: 5px !important;
  width: 112px !important;
}

.downOutlined {
  color: #FFFFFF !important;
  font-family: "MontserratBold";
  font-weight: bold !important;
  font-style: normal !important;
  font-size: 15px !important;
}

.userDetails {
  border-radius: 20px;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(62, 73, 84, 0.04);
  margin-top: 80px !important;
  display: flex;
  justify-content: center;
  text-align: center;
  overflow-y: hidden !important;

}

.content-spin {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
}

.grid-notification{
  width: 250px;
  display: grid;
  grid-template-columns: 1fr ;
  justify-content: center;
  align-items: center;
  gap: 10px;

}
@media screen and (max-width: 768px) {
  .grid-notification{
    /* width: 100%; */
    grid-template-columns: 1fr;
    gap: 10px;
    justify-content: center;
    align-items: center;
  }
}
.select-custom-dropdown {
  width: 100%;
}
.custom-dropdown .ant-select-item {
  background-color: #f0f8ff; 
  color: #1d3557; 
  font-family:"Montserrat important!";

}

.custom-dropdown .ant-select-item:hover {
  background-color: #00DD9E; 
  color: #fff; 
  font-family: "Montserrat important!";

}

.custom-dropdown .ant-select-item-option-selected {
  color: #1d3557; 
  font-family: "Montserrat important!";

}
.custom-dropdown .ant-select-item {
  font-family: "Montserrat important!";
  font-size: 18px;
}

.ant-select-selection-placeholder {
  font-family: "Montserrat important!";
  font-size: 16px; 
  color: #a0a0a0; 
}

.ant-select-selection-item {
  font-family: "Montserrat important!";
  font-size: 18px; 
  color: #041370; 

}

.dividir-user{
  width: 100%;
}
.dividir-user-text{
   font-family: "Montserrat !important"
}
.container-role{
  padding: 2px 0px;
}
.container-role-text{
  font-family: "MontserratSemiBold";
  color: #041370
}
.dividir-container-select{
  padding: 8px 0px 0px 0px;
}