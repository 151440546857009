
.shopify-distances-container {
  padding: 0px;
  margin-top: 0px;
  background-color: #f5f5f5;
  display: flex;
  justify-content: center;
}

.shopify-distances-card {
  background: #ffffff !important;
  border-radius: 16px !important;
  max-width: 100%;
  width: 100%;

}

.shopify-distances-header {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
  padding: 0px;
  margin-top: 0px;

}
.shopify-distances-title{
  margin-left: 10px;
}
.shopify-distances-card {
  margin: 0 !important;
  border: none;
}

.shopify-distances-card .ant-card-body {
  padding: 18px 18px 18px 18px !important;
}

.shopify-distances-card .ant-card-head {
  padding: 0 !important;
  margin: 0 !important;
}
@media (max-width: 768px) {
  .shopify-distances-container {
    padding: 0px;
  }

  .shopify-distances-card .ant-card-body {
    padding: 8px 4px 4px 4px !important;
  }

  .shopify-distances-header {
    margin-bottom: 10px;

  }
}
@media (max-width: 480px) {
  .shopify-distances-card .ant-card-body {
    padding: 8px 3px 3px 3px !important;

  }
  .shopify-distances-header {
    margin-bottom: 10px;
  }
}

.horizontal-form-shopifyDistances {
  display: grid;
  grid-template-columns: 1fr;
  gap: 15px;
  width: 100%;
  padding: 20px;
}

.form-group-shopifyDistances {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.form-label-shopifyDistances {
  font-weight: bold;
  margin-bottom: 5px;
  font-family: "MontserratRegular";

}

.input-style-shopifyDistances {
  padding: 10px;
  font-size: 16px;
  font-family: "MontserratRegular";

}
.shopify-distances-container-edit{
  display: flex;
  justify-content: center;
  align-items: center
}

.shopify-distances-title{
  margin-left: 10px;
}

.icon-edit {
  color: #e57373; 
  transition: color 0.3s ease;
}

.icon-edit:hover {
  color: #d32f2f; 
}

.icon-delete {
  color: #f44336; 
  transition: color 0.3s ease;
}

.icon-delete:hover {
  color: #b71c1c; 
}
